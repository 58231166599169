<template>
  <div class="payments-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/payments">Payments</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.booking_number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex">
        <div class="d-flex align-center" v-if="data.customer">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
            <div v-if="role != 'manager'">
              <router-link :to="`/customers/${data.customer.id}`">Open profile</router-link>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat width="110" height="42" class="status-block ms-auto">{{ data.status }}</v-card>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">{{ data.booking_number }}</div>
        <div class="mb-3">
          <span class="text--text">Payment date:</span>
          {{
            new Date(data.created_at).toLocaleString('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3 width50">
            <div class="font-weight-bold">Payment method</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ payMethodName(data.method) }}</div>
              <v-spacer></v-spacer>
              <img v-if="data.method" :src="getImage(data.method)" alt="payIcon" />
              <img v-if="data.method == 'visa_master'" src="@/assets/icon/master.png" alt="payIcon" />
            </v-card>
            <div class="font-weight-bold mt-5">Card bin</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.card_bin }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Card last 4 digits</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.card_last_4_digits }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Card country</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.card_country }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Card Holder</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.card_holder }}</div>
            </v-card>
          </div>
          <div class="ms-md-3 width50">
            <div class="font-weight-bold">Result code</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.result_code }}</div>
            </v-card>
            <div class="font-weight-bold mt-5">Result description</div>
            <v-card flat min-height="46" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text">{{ data.result_description }}</div>
            </v-card>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 d-flex align-center">
        <div class="text--text font-weight-bold"><span class="error--text">Failed: </span>{{ substatusName(data.substatus) }}</div>
        <v-spacer></v-spacer>
        <div class="text-h6 font-weight-bold">
          <span class="text--text">Total amount: </span>
          <span class="text-uppercase">{{ data.amount_currency }} {{ (data.amount / 100).toFixed(2) }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.$store.dispatch('getPaymentsItem');
  },
  methods: {
    getImage(icon) {
      icon == 'visa_master' ? (icon = 'visa') : '';
      return require(`@/assets/icon/${icon}.png`);
    },
    payMethodName(n) {
      if (n == 'mada') {
        return 'Mada';
      } else if (n == 'visa_master') {
        return 'Visa/Master card';
      } else if (n == 'apple_pay') {
        return 'Apple pay';
      }
    },
    substatusName(n) {
      if (n == 'card_invalid') {
        return 'Card invalid';
      } else if (n == 'cvv_invalid') {
        return 'CVV invalid';
      } else if (n == 'expiry_invalid') {
        return 'Expiry invalid';
      } else if (n == 'card_blocked') {
        return 'Card blocked';
      } else if (n == 'invalid_tries_limit') {
        return 'Invalid tries limit';
      } else if (n == 'client_limit_exceeded') {
        return 'Client limit exceeded';
      } else if (n == 'insufficient_funds') {
        return 'Insufficient funds';
      } else if (n == 'bin_blacklisted') {
        return 'Bin blacklisted';
      } else if (n == 'unknow') {
        return 'Unknow';
      } else {
        return '-';
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.payments;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setPaymentsItem');
  },
};
</script>

<style lang="scss">
.payments-item {
  .status-block {
    font-weight: 700;
    line-height: 42px !important;
    color: var(--v-primary-base);
    background: var(--v-primary-lighten3);
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
